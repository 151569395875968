import { lazy } from "react";

// const Home = lazy(() => import("./pages/Home"));
// const Tools = lazy(() => import("./pages/Tools"));
// const Forget = lazy(() => import("./pages/Forget/Forget.js"));
// const Remember = lazy(() => import("./pages/Forget/Remember.js"));

import Home from "./pages/Home";
import Tools from "./pages/Tools/Tools";
import Forget from "./pages/Forget/Forget";
import Remember from "./pages/Forget/Remember";

export default {
  root: {
    path: "/",
    exact: true,
    main: Home,
    name: "Home"
  },
  remember: {
    path: "/toolage/forget/:id",
    main: Remember,
    name: "Remember"
  },
  forget: {
    path: "/toolage/forget",
    main: Forget,
    name: "Forget"
  },
  toolage: {
    path: "/toolage",
    main: Tools,
    name: "Tools"
  }
};
