import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { getSecret } from "../../api/forget/forget";
import Error from "../../components/Error/Error";
import "./forget.scss";

const Remember = () => {
  const [secret, secSecret] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const { id } = useParams();
  useEffect(() => {
    getSecret(id)
      .then(json => {
        if (json.error) {
          setError(json.error);
        } else {
          secSecret(json.secret);
        }
      })
      .catch(err => {
        setError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  return (
    <div className="remember">
      <Error error={error} />

      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <>
          {error !== "" ? null : (
            <div>
              <textarea
                contentEditable={false}
                value={secret}
                onChange={e => {
                  e.preventDefault();
                }}
              />
            </div>
          )}
        </>
      )}

      <Link to={`/toolage/forget`}>Forget something else</Link>
    </div>
  );
};

export default Remember;
