import React from "react";
import { Link } from "react-router-dom";

export default function NavItem({ link, children, className }) {
  return (
    <li className={`nav-item ${className}`}>
      <Link to={link}>{children}</Link>
    </li>
  );
}
