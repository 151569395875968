const URL = "https://theforgetter.herokuapp.com/secret";

function get(url) {
  return new Promise((resolve, reject) => {
    return fetch(url)
      .then(res => resolve(res.json()))
      .catch(err => reject(err));
  });
}

function post(url, data) {
  return new Promise((resolve, reject) => {
    return fetch(url, {
      method: "post",
      headers: {
        "content-type": "Application/json"
      },
      body: JSON.stringify(data)
    })
      .then(res => resolve(res.json()))
      .catch(err => reject(err));
  });
}

export function saveSecret(secret) {
  return post(URL, { secret: secret, option: "First request" });
}

export function getSecret(id) {
  return get(`${URL}/${id}`);
}
