import React, { Component } from "react";

import PropTypes from "prop-types";
import NavGroup from "./NavGroup";
import NavItem from "./NavItem";
import routes from "../../routes";
import "./navbar.scss";

export default class Navbar extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  setIsOpen = isOpen => {
    this.setState({ open: isOpen });
  };

  render() {
    return (
      <nav
        className={!this.state.open ? "closed" : ""}
        onMouseEnter={() => {
          this.setIsOpen(true);
        }}
        onMouseLeave={() => {
          this.setIsOpen(false);
        }}
      >
        <div className="logo">
          <NavItem link={"/"}>JG</NavItem>
        </div>

        <div className="children">
          <NavGroup
            className="tools"
            header={{ link: routes.toolage.path, name: routes.toolage.name }}
          >
            <NavItem link={routes.forget.path}>Forget</NavItem>
          </NavGroup>
        </div>
      </nav>
    );
  }
}
