import React, { Component } from "react";

export default class Name extends Component {
	render() {
		return (
			<div>
				<h1 className="name">JG</h1>
			</div>
		);
	}
}
