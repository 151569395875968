import React, { useState } from "react";
import NavItem from "./NavItem";

export default function NavGroup({ _open, children, header, className }) {
  const [isOpen, setIsOpen] = useState(_open);

  return (
    <div
      className={`nav-group-container ${className}`}
      onMouseEnter={() => {
        setIsOpen(true);
      }}
      onMouseLeave={() => {
        setIsOpen(false);
      }}
    >
      <NavItem className="header" link={header.link}>
        {header.name}
      </NavItem>

      <ul className={`nav-group ${isOpen ? "open" : ""}`}>{children}</ul>
    </div>
  );
}
