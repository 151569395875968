import React from "react";
import "./error.scss";

export default function Error({ error }) {
  if (error === null || error === "" || error === undefined) {
    return null;
  }

  return <div className="error">{error}</div>;
}
