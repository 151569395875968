import React, { Component } from "react";
import Name from "../views/Name";

class Home extends Component {
	render() {
		return (
			<div>
				<Name fill="#FFF" />
			</div>
		);
	}
}

export default Home;
