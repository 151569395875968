import React, { useState, Suspense } from "react";
import "./forget.scss";
import { saveSecret } from "../../api/forget/forget";
import { Link } from "react-router-dom";
import Error from "../../components/Error/Error";

const Forget = () => {
  const defaultValue = "Your secrets here...";
  const [value, setValue] = useState(defaultValue);
  const [secretId, setSecretId] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const sendSecret = () => {
    setLoading(true);
    saveSecret(value)
      .then(json => {
        if (json.error) {
          setError(json.error);
        } else if (json.url) {
          const secretUrl = json.url;
          const tokens = secretUrl.split("/");
          const secretId = tokens[tokens.length - 1];
          setSecretId(secretId);
          setLoading(false);
        }
      })
      .catch(err => {
        setError(err);
      });
  };

  const setSecret = secret => {
    if (value === defaultValue) {
      setValue(secret.substr(defaultValue.length));
    } else {
      setValue(secret);
    }
  };

  return (
    <div className="forget">
      <h2>Remember something and then forget it</h2>
      <p>
        All secrets are stored in volatile memory only. Secrets might be
        forgotten before use.
      </p>

      <Error error={error} />

      {secretId === "" ? (
        <>
          {loading ? (
            <div>Remembering to forget...</div>
          ) : (
            <div>
              <p>Forgets after first request</p>
              <textarea
                value={value}
                onChange={e => setSecret(e.target.value)}
              ></textarea>
              <button onClick={sendSecret}>Save</button>
            </div>
          )}
        </>
      ) : (
        <div>
          <Link
            to={`/toolage/forget/${secretId}`}
          >{`${window.location.href}/${secretId}`}</Link>
        </div>
      )}
    </div>
  );
};

export default Forget;
