import React, { Component } from "react";
import { Link } from "react-router-dom";
import routes from "../../routes";
import "./tools.scss";
export default class Tools extends Component {
  render() {
    return (
      <div>
        <h1>Tools</h1>
        <div className="page">
          <p>
            These are tools I use commonly and wanted grouped together or
            re-implemented
          </p>
          <ul className="tools">
            <li>
              <div>
                <Link to={routes.forget.path}>
                  <h3>Forget</h3>
                  <p>
                    This tool allows you to share a secret via a link that will
                    explode after first use
                  </p>
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
