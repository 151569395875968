import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { withRouter } from "react-router";

import Navbar from "./components/Navbar/Navbar.js";
import routes from "./routes";
import "normalize.css";
import "./styles/app.scss";

// Create a new component that is "connected" to the router.
const NavbarWithRouter = withRouter(Navbar);

const App = () => (
  <Router>
    <div className="app-container">
      <NavbarWithRouter />
      <main className="content">
        <Switch>
          {/* <Suspense fallback={<div>Loading...</div>}> */}
          {/* render page content */}
          {Object.keys(routes).map((key, index) => {
            const route = routes[key];
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                children={<route.main />}
              />
            );
          })}
          {/* </Suspense> */}

          {/* <Route path="/">404 not found</Route> */}
        </Switch>
      </main>
    </div>
  </Router>
);

export default App;
